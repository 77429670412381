import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSpecialities(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/specialities', {params: queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addSpeciality(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/specialities', data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateSpeciality(ctx, {id, data}) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/specialities/' + id, data)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteSpeciality(ctx, Id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete('/specialities/' + Id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchSpeciality(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/specialities/' + id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        }
    }
}
